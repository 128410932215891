/* stylelint-disable selector-class-pattern */

.rc-pagination {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.rc-pagination ul, .rc-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rc-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: "";
}

.rc-pagination-total-text {
  display: inline-block;
  height: 28px;
  margin-right: 15px;
  line-height: 26px;
  vertical-align: middle;
}

.rc-pagination-item {
  display: inline-block;
  height: 28px;
  margin-right: 15px;
  font-family: Halcyon-Regular;
  line-height: 26px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}

.rc-pagination-item a {
  display: block;
  transition: none;
  font-family: Halcyon-Regular;
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #193755;
}

.rc-pagination-item a:hover {
  text-decoration: none;
  font-weight: bold;
}

.rc-pagination-item:focus, .rc-pagination-item:hover {
  font-weight: bold;
}

.rc-pagination-item:focus a, .rc-pagination-item:hover a {
  font-weight: bold;
}

.rc-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border: none;
}

.rc-pagination-item-active a {
  color: #193755;
  font-weight: bold;
}


.rc-pagination-item-active:focus a, .rc-pagination-item-active:hover a {
  color: #193755;
  cursor: text;
}

.rc-pagination-jump-prev, .rc-pagination-jump-next {
  outline: 0;
}

.rc-pagination-jump-prev button, .rc-pagination-jump-next button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 0;
  margin: 0;
}

.rc-pagination-jump-prev button::after, .rc-pagination-jump-next button::after {
  display: block;
  content: "•••";
  font-size: 10px;
}

.rc-pagination-prev, .rc-pagination-jump-prev, .rc-pagination-jump-next {
  margin-right: 15px;
}

.rc-pagination-prev, .rc-pagination-next, .rc-pagination-jump-prev, .rc-pagination-jump-next {
  display: inline-block;
  height: 28px;
  color: rgba(0 ,0 ,0 , 0.85);
  font-family: Halcyon-Regular;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}

.rc-pagination-prev, .rc-pagination-next {
  outline: 0;
}

.rc-pagination-prev button, .rc-pagination-next button {
  color: rgba(0,0,0,0.85);
  cursor: pointer;
  user-select: none;
}

.rc-pagination-prev:hover button, .rc-pagination-next:hover button {
  border-color: #40a9ff;
}

.rc-pagination-prev .rc-pagination-item-link, .rc-pagination-next .rc-pagination-item-link {
  font-family: Halcyon-Regular;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #193755;
}

.rc-pagination-prev:focus .rc-pagination-item-link, .rc-pagination-prev:hover .rc-pagination-item-link, .rc-pagination-next:focus .rc-pagination-item-link, .rc-pagination-next:hover .rc-pagination-item-link {
  color: #1890ff;
  border: none;
}

.rc-pagination-prev button::after {
  content: "‹";
  display: block;
}

.rc-pagination-next button::after {
  content: "›";
  display: block;
}

.rc-pagination-disabled, .rc-pagination-disabled:hover, .rc-pagination-disabled:focus {
  cursor: text;
  opacity: 0.5;
}

.rc-pagination-slash {
  margin: 0 10px 0 5px;
}

.rc-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}

@media all and (-ms-high-contrast: none) {
  .rc-pagination-options *::-ms-backdrop, .rc-pagination-options {
    vertical-align: top;
 }
}

.rc-pagination-options-size-changer.rc-select {
  display: inline-block;
  width: auto;
  margin-right: 15px;
}

.rc-pagination-options-quick-jumper {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  vertical-align: top;
}

.rc-pagination-options-quick-jumper input {
  width: 50px;
  margin: 0 8px;
}

.rc-pagination-simple .rc-pagination-prev, .rc-pagination-simple .rc-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}

.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link, .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}

.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link::after, .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}

.rc-pagination-simple .rc-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 15px;
}

.rc-pagination-simple .rc-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 15px;
  text-align: center;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}

.rc-pagination-simple .rc-pagination-simple-pager input:hover {
  border: none;
}

.rc-pagination.rc-pagination-disabled {
  cursor: text;
  opacity: 0.5;
}

@media only screen and ( max-width: 992px ) {

  .rc-pagination-item-after-jump-prev, .rc-pagination-item-before-jump-next {
    display: none;
  }

}
@media only screen and ( max-width: 576px ) {

  .rc-pagination-options {
    display: none;
  }
}
