body, html {
  padding: 0;
  margin: 0;
  font-family: Halcyon-Regular, serif;
}

.layoutRow{
  display: flex;
  flex: 1 1;
  background-color: white;
  margin-top: 54px;
}

.main{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
}

.privateLayout{
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 54px;
}

#mobile{
  position: relative;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
  overflow: hidden;
  background-color: #193755;
}

.navigation {
  height: 100vh;
}

#sidebar {
  position: absolute;
  z-index: 10;
  list-style-type: none;
  margin: 20px 0 0 ;
  padding: 0;
  overflow: hidden;
  right: 20px;
}

#sidebar button{
  margin-left: 15px;
}

#sidebar img{
  margin-left: auto;
  margin-right: 0;
  opacity: 0;
}

.navigation #sidebar img{
  opacity: 1;
  padding-top: 2px;
}

.eoloContainerScale{
  left: -80% !important;
  overflow: hidden;
  transform: scale(0.99) translateZ(0);
  height: 90vh;
  margin-top: 5vh;
  border-radius: 10.4px;
  box-shadow: -2px 10px 70px 0 rgb(0 0 0 / 70%);
}

@media screen and ( min-width: 769px ) {

  .eoloContainerScale {
    left: 0 !important;
    transform: none;
    height: 100%;
    margin-top: 0;
    border-radius: 0;
  }
}

.eoloContainer {
  left: 0;
  position: relative;
  transition: 0.5s transform ease-in-out,0.5s left ease-in-out;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
}

.bannerCookiebtnPrimary, .bannerCookieDecline{
  background: transparent !important;
  border: 2px solid white !important;
  color: white !important;
  padding: 15px !important;
  border-radius: 10px !important;
  margin: 7px !important;
}

.bannerCookieContainer{
  justify-content: center !important;
  align-items: center !important;
  background: #193755 !important;
  opacity: 0.91;
  color: white;
  padding: 15px 0;
}

.bannerCookieContainer div:last-child{
  display: flex;
  flex-direction: column;
}

.bannerCookieContent{
  margin: auto;
  flex: 0 1 60% !important;
  position: relative !important;
}

.bannerCookieContent a{
  color: white !important;
}

.closeBannercookie{
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 25px;
  font-weight: 500;
  font-family: Halcyon-Medium, sans-serif;
  transform: translate(250px, -40px) !important;
  cursor: pointer;
}

#news p{
  margin: 0;
  padding: 0;
}

#news div:first-child{
  border-top: none;
}

@font-face {
  font-family: Halcyon-Regular;
  src: url("./assets/fonts/Halcyon-Regular.eot");
  src: url("./assets/fonts/Halcyon-Regular.woff2") format("woff2"),url("./assets/fonts/Halcyon-Regular.woff") format("woff"),url("./assets/fonts/Halcyon-Regular.ttf") format("truetype"),url("./assets/fonts/Halcyon-Regular.svg#svgFontName") format("svg"),url("./assets/fonts/Halcyon-Regular.eot?#iefix") format("embedded-opentype");
}

@font-face {
  font-family: Halcyon-Medium;
  src: url("./assets/fonts/Halcyon-Medium.eot");
  src: url("./assets/fonts/Halcyon-Medium.woff2") format("woff2"),url("./assets/fonts/Halcyon-Medium.woff") format("woff"),url("./assets/fonts/Halcyon-Medium.ttf") format("truetype"),url("./assets/fonts/Halcyon-Medium.eot?#iefix") format("embedded-opentype");

  /* url("./assets/fonts/Halcyon-Medium.svg#svgFontName") format("svg"), */
}

@font-face {
  font-family: Halcyon-SemiBold;
  src: url("./assets/fonts/Halcyon-SemiBold.eot");
  src: url("./assets/fonts/Halcyon-SemiBold.woff2") format("woff2"),url("./assets/fonts/Halcyon-SemiBold.woff") format("woff"),url("./assets/fonts/Halcyon-SemiBold.ttf") format("truetype"),url("./assets/fonts/Halcyon-SemiBold.svg#svgFontName") format("svg"),url("./assets/fonts/Halcyon-SemiBold.eot?#iefix") format("embedded-opentype");
}

@font-face {
  font-family: Halcyon-Bold;
  src: url("./assets/fonts/Halcyon-Bold.eot");
  src: url("./assets/fonts/Halcyon-Bold.woff2") format("woff2"),url("./assets/fonts/Halcyon-Bold.woff") format("woff"),url("./assets/fonts/Halcyon-Bold.ttf") format("truetype"),url("./assets/fonts/Halcyon-Bold.svg#svgFontName") format("svg"),url("./assets/fonts/Halcyon-Bold.eot?#iefix") format("embedded-opentype");
}

@font-face {
  font-family: Halcyon-Black;
  src: url("./assets/fonts/Halcyon-Black.eot");
  src: url("./assets/fonts/Halcyon-Black.woff2") format("woff2"),url("./assets/fonts/Halcyon-Black.woff") format("woff"),url("./assets/fonts/Halcyon-Black.ttf") format("truetype"),url("./assets/fonts/Halcyon-Black.svg#svgFontName") format("svg"),url("./assets/fonts/Halcyon-Black.eot?#iefix") format("embedded-opentype");
}

@font-face {
  font-family: Halcyon-Light;
  src: url("./assets/fonts/Halcyon-Light.eot");
  src: url("./assets/fonts/Halcyon-Light.woff2") format("woff2"),url("./assets/fonts/Halcyon-Light.woff") format("woff"),url("./assets/fonts/Halcyon-Light.ttf") format("truetype"),url("./assets/fonts/Halcyon-Light.eot?#iefix") format("embedded-opentype");
  
  /* url("./assets/fonts/Halcyon-Light.svg#svgFontName") format("svg"), */
  
} 